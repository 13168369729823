import React, { useEffect } from 'react'
import Seo from './../components/seo/seo'
import Layout from './../components/layout/Layout'
import { Link } from 'gatsby'

const Index = () => {
  useEffect(() => {
    setTimeout(() => {
      if (document.getElementsByClassName('compnay-info').style) {
        document.getElementsByClassName('compnay-info').style.padding =
          '0px 15px'
      }
    }, 700)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      var rellax = new window.Rellax('.rellax', {
        breakpoints: [576, 768, 1201],
      })
      window.AOS.init({
        duration: 1200,
        easing: 'ease-out-back',
      })

      window.$(document).ready(function () {
        const slider = window.jQuery('.slider-main')
        slider.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.slider-nav',
          responsive: [
            {
              breakpoint: 980,
              settings: {
                arrows: true,
              },
            },
          ],
        })
        slider.on('wheel', function (e) {
          e.preventDefault()
          if (e.originalEvent.deltaY < 0) {
            window.$(this).slick('slickPrev')
          } else {
            window.$(this).slick('slickNext')
          }
        })

        window.$('.slider-nav').slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          vertical: true,
          asNavFor: '.slider-main',
          dots: false,

          focusOnSelect: true,
          verticalSwiping: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                vertical: true,
              },
            },
            {
              breakpoint: 768,
              settings: {
                vertical: true,
              },
            },
            {
              breakpoint: 580,
              settings: {
                vertical: true,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 380,
              settings: {
                vertical: true,
                slidesToShow: 2,
              },
            },
          ],
        })
      })

      window.TweenLite.defaultEase = window.Linear.easeNone
      var controller = new window.ScrollMagic.Controller()
      var tl = new window.TimelineMax()
      tl.from('section.panel.turqoise', 1, { xPercent: 100 })
      tl.from('section.panel.bordeaux', 1, { xPercent: 100 })
      tl.from('section.panel.brown', 1, { xPercent: 100 })

      new window.ScrollMagic.Scene({
        triggerElement: '#pinMaster',
        triggerHook: 'onLeave',
        duration: '200%',
      })
        .setPin('#pinMaster')
        .setTween(tl)
        .addIndicators({
          colorTrigger: 'white',
          colorStart: 'white',
          colorEnd: 'white',
          indent: 40,
        })
        .removeIndicators()
        .addTo(controller)
    }, 700)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById('iframe-0.7407671076333555')) {
        document.getElementById('iframe-0.7407671076333555').style.display =
          'flex'
      } else if (document.getElementsByClassName('.compnay-info').style) {
        document.getElementsByClassName('.compnay-info').style.cssText =
          'display: flex; align-items:flex-end;'
      }
    }, 700)
  }, [])

  return (
    <>
      <Seo
        title={'Best Software Company - immence'}
        description={
          'We build custom mobile applications, websites, and enterprise solutions with a strategic mindset focused on driving incremental growth.'
        }
      />
      <Layout>
        {/* hero section */}
        <section className="hero-section bg-gray aboutImmence">
          <div className="hero-section-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 hero-content">
                  <h1 className="hero-heading">
                    Future Proof
                    <br />
                    <span className="blue-span"> Digital Solutions</span>
                  </h1>
                  <p>
                    Today's innovation is tomorrow's old news. Because of this,
                    brands need to invest in software development as a service
                    that can produce novel, creative ways to engage customers.
                  </p>
                  <p>
                    The team of UI/UX experts, full-stack developers, and
                    technical analysts at immence are dedicated to helping you
                    keep your brand’s digital presence fresh, engaging, and
                    dynamic.
                  </p>
                  <Link
                    to="contact"
                    className="btn primary-btn btn-ouline btn-contact"
                  >
                    Let's talk business!
                  </Link>
                </div>
                <div className="col-lg-6 hero-img">
                  <div className="hero-vector">
                    <img
                      src={require('../img/heroImg/home.png').default}
                      className="img-fluid"
                      alt="immence - We build websites for b2b brands"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end hero section */}
        {/* service section */}
        <section className="com-section service-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="col-lg-12" data-aos="fade-up">
                  <h2 className="hero-heading">
                    Full <span className="pink-span">Cycle </span> <br />
                    <span className="pink-span">Development</span> Agency
                  </h2>
                  <p className="section-main-para">
                    Exceptional services from experienced professionals with
                    end-to-end support and expert guidance.
                  </p>
                </div>
                <div className="col-lg-12" data-aos="fade-up">
                  <div className="card-margin-second">
                    <div className="card-services">
                      <div className="card-title">
                        <img
                          src={
                            require('../img/development/solutions.svg').default
                          }
                          alt="immence - Web Development"
                        />
                        <h2 className="text-primary card-heading">
                          Solution Design
                        </h2>
                      </div>
                      <div className="card-content">
                        <p>
                          Use a superb brain into your digital product. We
                          implement the most complex solution architecture to
                          establish the foundation of an efficient, performant,
                          and scalable digital solution..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12" data-aos="fade-up">
                  <div className="card-margin-second">
                    <div className="card-services">
                      <div className="card-title">
                        <img
                          src={
                            require('../img/development/mobile_development.svg')
                              .default
                          }
                          alt="immence - Web Development"
                        />
                        <h2 className="text-primary card-heading">
                          Mobile App Development
                        </h2>
                      </div>
                      <div className="card-content">
                        <p>
                          Bring your business to customers within arm's reach.
                          We build cutting-edge mobile apps with the latest
                          technologies to attract users to your business
                          offering through a magnetic user experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12" data-aos="fade-up">
                  <div className="card-margin-last">
                    <div className="card-services">
                      <div className="card-title">
                        <img
                          src={require('../img/development/design.svg').default}
                          alt="immence - Web Development"
                        />
                        <h2 className="text-primary card-heading">
                          UI/UX Design
                        </h2>
                      </div>
                      <div className="card-content">
                        <p>
                          We mix product innovation with optimum usability. We
                          produce an engaging and human-centric design that is
                          scalable and enjoyable to use through our iterative
                          design approach and comprehensive prototyping. From
                          the discovery process to the last milestones, our team
                          is present to guarantee that no aspect of your vision
                          is lost in transition.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="col-lg-12" data-aos="fade-up">
                  <div className="card-margin-first">
                    <div className="card-services">
                      <div className="card-title">
                        <img
                          src={
                            require('../img/development/custom_development.svg')
                              .default
                          }
                          alt="immence - Web Development"
                        />
                        <h2 className="text-primary card-heading">
                          Custom Software Development
                        </h2>
                      </div>
                      <div className="card-content">
                        <p>
                          In order to succeed in today's market, businesses must
                          be able to innovate quickly and more efficiently than
                          the competition. Our custom software development
                          services provide the flexibility and functionality
                          required to meet unique industry needs and adapt to an
                          ever-changing landscape.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12" data-aos="fade-up">
                  <div className="card-margin-last">
                    <div className="card-services">
                      <div className="card-title">
                        <img
                          src={
                            require('../img/development/web_development.svg')
                              .default
                          }
                          alt="immence - Web Development"
                        />
                        <h2 className="text-primary card-heading">
                          Web development
                        </h2>
                      </div>
                      <div className="card-content">
                        <p>
                          We develop websites that benefit both your company and
                          users. Our online solutions work seamlessly and
                          consistently across all platforms, whether it's a
                          complicated enterprise platform and data storage, a
                          responsive web app, or a marketing site.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12" data-aos="fade-up">
                  <div className="card-margin-last">
                    <div className="card-services">
                      <div className="card-title">
                        <img
                          src={
                            require('../img/development/game_development.svg')
                              .default
                          }
                          alt="immence - Web Development"
                        />
                        <h2 className="text-primary card-heading">
                          Game Development
                        </h2>
                      </div>
                      <div className="card-content">
                        <p>
                          Ignite your game development fantasies with our highly
                          experienced team of game developers. We are a creative
                          game app development firm that operates solely for the
                          purpose of meeting the various needs of our customers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12" data-aos="fade-up">
                  <div className="card-margin-second">
                    <div className="card-services">
                      <div className="card-title">
                        <img
                          src={
                            require('../img/development/cloud_devops.svg')
                              .default
                          }
                          alt="immence - Web Development"
                        />
                        <h2 className="text-primary card-heading">
                          DevOps &amp; Cloud
                        </h2>
                      </div>
                      <div className="card-content">
                        <p>
                          Lead your cloud development just like the best in
                          business do. Our approach automates software
                          development processes so that workloads may be built,
                          tested, released, and managed more quickly and
                          reliably.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end service section */}
        {/* portfolio section */}
        <div id="placeholder1">
          <div id="pinMaster">
            <div id="pinContainer">
              <section className="panel com-section section-center portfolio-section bg-vrs">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <h2 className="hero-heading">
                        Comprehensive way to
                        <span className="vrs-span">Personalized </span>
                        <br />
                        Weight Loss
                        <span className="vrs-span"> </span>
                      </h2>
                      <p className="section-main-para">
                        Have you tried other weight loss programs with little or
                        no results? Weight Loss Direct Intelligence proprietary
                        technology creates a completely unique protocol based on
                        your current health conditions.
                      </p>
                    </div>
                    <div className="col-lg-6 portfolio-img">
                      <img
                        src={require('../img/wlt_case_study.png').default}
                        alt="immence - Portfolio Mockup"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="panel turqoise section-center portfolio-section bg-dzy">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <h2 className="hero-heading">
                        Products you <br />
                        <span className="dzy-span"> desire </span> at <br />
                        prices <span className="dzy-span"> you wish</span>
                      </h2>
                      <p className="section-main-para">
                        Koboldo is a next generation eCommerce platform bringing
                        savings to retail customers using group buying
                        techniques. This is set to change the current eCommerce
                        landscape with features like name-your-own-price, price
                        bargaining, etc implemented on a single platform.
                      </p>
                      {/* <a href="#" target="_blank" rel="noreferrer" class="btn btn-outline-dark-primary btn-ouline" tabindex="0">
              View Project
            </a> */}
                    </div>
                    <div className="col-lg-6 portfolio-img">
                      <img
                        src={require('../img/koboldo_case_study.png').default}
                        alt="immence - Portfolio Mockup"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="panel bordeaux section-center portfolio-section bg-pfn">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <h2 className="hero-heading">
                        Connect with
                        <span className="pfn-span"> thousands </span>
                        <br />
                        of other
                        <span className="pfn-span"> foodies</span>
                      </h2>
                      <p className="section-main-para">
                        Plates Social is a food based social media app with one
                        sole purpose. To express yourself while simultaneously
                        connecting you with millions of other chefs! The idea
                        behind Plates goes way beyond food.
                      </p>
                      {/* <a href="#" target="_blank" rel="noreferrer" class="btn btn-outline-dark-primary btn-ouline" tabindex="0">
              View Project
            </a> */}
                    </div>
                    <div className="col-lg-6 portfolio-img">
                      <img
                        src={require('../img/plates_case_study.png').default}
                        alt="immence - Portfolio Mockup"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        {/*end portfolio section */}
        {/* process section */}
        <section className="com-section process-section" id="element">
          <div className="container">
            <div className="row position-relative">
              <div className="col-lg-5">
                <div className="process-heading">
                  <h2 className="hero-heading">
                    How
                    <br />
                    <span className="blue-span">immence</span> works
                  </h2>
                  <p className="section-main-para">
                    We use the most modern frameworks and efficient
                    methodologies to ensure a smooth delivery and high
                    performing product.
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="process-list" data-aos="fade-up">
                  <div className="process-box proces-box-1">
                    <div className="process-card">
                      <div className="process-img d-flex justify-content-center">
                        <img
                          src={
                            require('../img/development_approach/research.svg')
                              .default
                          }
                          alt="immence - Learning & Planning"
                        />
                      </div>
                      <div className="process-content">
                        <h2 className="h1">Research and Discovery</h2>
                        <p>
                          Our main priority is to fully understand your business
                          challenge and the idea behind the future solution. We
                          carefully analyze your requirements, research the
                          market and existing competitors, and evaluate the
                          future concept.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-box proces-box-2">
                    <div className="process-card">
                      <div className="process-img d-flex justify-content-center">
                        <img
                          src={
                            require('../img/development_approach/scope_defination.svg')
                              .default
                          }
                          alt="immence - UX & Design"
                        />
                      </div>
                      <div className="process-content">
                        <h2 className="h1">Scope Definition</h2>
                        <p>
                          We deeply study your objectives in defining the
                          project's features, creating a list of all its
                          modules, and analyzing the project's technical
                          viability. We can now define the solution's tech stack
                          and prepare a project roadmap as a result.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-box proces-box-3">
                    <div className="process-card">
                      <div className="process-img d-flex justify-content-center">
                        <img
                          src={
                            require('../img/development_approach/ux_design.svg')
                              .default
                          }
                          alt="immence - Development"
                        />
                      </div>
                      <div className="process-content">
                        <h2 className="h1">UI/UX Design</h2>
                        <p>
                          We start by defining the information architecture of
                          your mobile or web solution to define possible user
                          flows. Finally, our UX designer prepares a wiferame,
                          clickable prototype that lets us have the first look
                          and feel of the future product.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-box proces-box-4">
                    <div className="process-card">
                      <div className="process-img d-flex justify-content-center">
                        <img
                          src={
                            require('../img/development_approach/development.svg')
                              .default
                          }
                          alt="immence - Integration"
                        />
                      </div>
                      <div className="process-content">
                        <h2 className="h1">Development</h2>
                        <p>
                          First, we create the software architecture and later
                          develop a complete solution, including robust backend
                          and smooth frontend. Adhering to Agile philosophy, we
                          develop in iterations, show demos, and gather
                          feedback.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-box proces-box-5">
                    <div className="process-card">
                      <div className="process-img d-flex justify-content-center">
                        <img
                          src={
                            require('../img/development_approach/qa_and_testing.svg')
                              .default
                          }
                          alt="immence - Testing & Quality Assurance"
                        />
                      </div>
                      <div className="process-content">
                        <h2 className="h1">QA &amp; Testing</h2>
                        <p>
                          Each interaction is carefully tested to check the
                          product’s security, performance, operation as well as
                          specification compliance. Our goal is to check if the
                          solution works as intended, corresponds to users’
                          expectations, and remains 100% secure.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-box proces-box-6">
                    <div className="process-card">
                      <div className="process-img d-flex justify-content-center">
                        <img
                          src={
                            require('../img/development_approach/pre_launch.svg')
                              .default
                          }
                          alt="immence - Deployment"
                        />
                      </div>
                      <div className="process-content">
                        <h2 className="h1">Pre Launch </h2>
                        <p>
                          We perform quality pre-launch tests to ensure smooth
                          and hitch-free product launch. We also provide all
                          essential test case reports, installation guides, app
                          tutorials, and user manuals for easy application
                          delivery.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-box proces-box-7">
                    <div className="process-card">
                      <div className="process-img d-flex justify-content-center">
                        <img
                          src={
                            require('../img/development_approach/post_launch_maintenance.svg')
                              .default
                          }
                          alt="immence - Maintenance"
                        />
                      </div>
                      <div className="process-content">
                        <h2 className="h1">Post-launch maintenance </h2>
                        <p>
                          Once a product is live, it is important to organize an
                          efficient support process to control the quality of
                          the project, continuously perform security checks,
                          install new updates, and react to users’ feedback and
                          changing business requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end process section */}
        {/* why section */}
        <section className="com-section why-section bg-gray">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-duration={1500}
                data-aos-delay={50}
              >
                <h2 className="hero-heading text-center">
                  What’s so<span className="yellow-span"> special</span> about
                  <span style={{ color: '#0231c8' }}> immence?</span>
                </h2>
                <p className="section-main-para heading-setting text-center mx-auto">
                  Now what is it that gets us so convinced we can cope with your
                  challenge and ours will be a happy ending? On a large scale,
                  it’s our persistence. Under no circumstances are we to give in
                  to sophisticated calls, as inventiveness and flexibility are
                  our nature.
                </p>
              </div>
            </div>
            <div className="row flexSec">
              <div className="col-lg-4">
                <div className="choose-card" data-aos="fade-down">
                  <div className="choose-card-icon">
                    <img
                      src={require('../img/choose_icon.svg').default}
                      alt="Why choose immence?"
                    />
                  </div>
                  <div className="choose-content">
                    <h3>Envision</h3>
                    <p>
                      Our approach begins with the end-users. How do they need
                      to work and what do they need to accomplish? That along
                      with the other priorities of the organization comprise the
                      technology strategy.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="choose-card" data-aos="fade-up">
                  <div className="choose-card-icon">
                    <img
                      src={require('../img/choose_icon_2.svg').default}
                      alt="Why choose immence?"
                    />
                  </div>
                  <div className="choose-content">
                    <h3>Execute</h3>
                    <p>
                      When it comes to executing business strategy, good enough
                      isn’t going to cut it. We align teams, foster
                      collaboration, and help accomplish the real mission.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="choose-card" data-aos="fade-down">
                  <div className="choose-card-icon">
                    <img
                      src={require('../img/choose_icon_3.svg').default}
                      alt="Why choose immence?"
                    />
                  </div>
                  <div className="choose-content">
                    <h3>Enhance</h3>
                    <p>
                      Enhancing business is one of the crucial elements to the
                      success of any idea. With our immence skill sets, we will
                      provide guidance from the inception of the project to its
                      effective realization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end why section */}
        {/* testimonial section */}
        <section className="com-section testimonial-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="d-inline-block position-relative"
                  data-aos="fade-up"
                >
                  <h2 className="hero-heading">
                    Once a{' '}
                    <span className="yellow-span">
                      client,
                      <br />
                    </span>
                    always a <span className="yellow-span">partner</span>
                  </h2>
                  <p className="section-main-para">
                    Making strong partnerships was central to our long-term plan
                    from day one. Regardless of project scale and complexity, we
                    keep being an all-time resourceful tech provider, making
                    clients all around the business horizons have their hearts
                    set on immence.
                  </p>
                  <div className="control-btn">
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#testimonalCarousel"
                      data-bs-slide="prev"
                    >
                      <i className="fi-rr-angle-left" />
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#testimonalCarousel"
                      data-bs-slide="next"
                    >
                      <i className="fi-rr-angle-right" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 position-relative">
                <div
                  id="testimonalCarousel"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="testimonial-card">
                        <div className="client-img justify-content-center d-flex">
                          <img
                            src={require('../img/Johnson_a.jpeg').default}
                            alt="immence - Clients testimonial"
                          />
                        </div>
                        <div className="comma-icon justify-content-center d-flex">
                          <img
                            src={require('../img/comma.svg').default}
                            alt="comma icon"
                          />
                        </div>
                        <div className="testimonial-content">
                          <h3>Johnson A.</h3>
                          <h5>Founder &amp; CEO</h5>
                          <p>
                            “immence is an excellent business partner who has
                            the ability to fulfill our complex architecture and
                            engineering needs. We were able to count on them
                            over and over again while they continued to deliver
                            on time.”
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="testimonial-card">
                        <div className="client-img justify-content-center d-flex">
                          <img
                            src={require('../img/leon.png').default}
                            alt="immence - Clients testimonial"
                          />
                        </div>
                        <div className="comma-icon justify-content-center d-flex">
                          <img
                            src={require('../img/comma.svg').default}
                            alt="comma icon"
                          />
                        </div>
                        <div className="testimonial-content">
                          <h3>Leon B.</h3>
                          <h5>President</h5>
                          <p>
                            The team works in a very professional manner,
                            delivering quality and timely outputs according to
                            specifications. They've also impressed with their
                            communication and problem-solving skills; they
                            respond and attend to requests promptly.”
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="testimonial-card">
                        <div className="client-img justify-content-center d-flex">
                          <img
                            src={require('../img/prem.jpeg').default}
                            alt="immence - Clients testimonial"
                          />
                        </div>
                        <div className="comma-icon justify-content-center d-flex">
                          <img
                            src={require('../img/comma.svg').default}
                            alt="comma icon"
                          />
                        </div>
                        <div className="testimonial-content">
                          <h3>Pithiya P.</h3>
                          <h5>CEO/Founder</h5>
                          <p>
                            “The software that immence has created increased the
                            turnaround of the company from 25–27 minutes down to
                            9 minutes. Their solid project management process
                            and development skills have helped us maintain a
                            high performance. Above all, they are delivering
                            what is required!”
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="testimonial-card">
                        <div className="client-img justify-content-center d-flex">
                          <img
                            src={require('../img/adam_k.png').default}
                            alt="immence - Clients testimonial"
                          />
                        </div>
                        <div className="comma-icon justify-content-center d-flex">
                          <img
                            src={require('../img/comma.svg').default}
                            alt="comma icon"
                          />
                        </div>
                        <div className="testimonial-content">
                          <h3>Adam K.</h3>
                          <h5>CEO</h5>
                          <p>
                            “I am grateful to have worked with immence to help
                            me bring my business web design to a real functional
                            website that works perfectly. immence and his team
                            followed the requirements 100%.”
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-btn-hidden">
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#testimonalCarousel"
                    data-bs-slide="prev"
                  >
                    <i className="fi-rr-angle-left" />
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#testimonalCarousel"
                    data-bs-slide="next"
                  >
                    <i className="fi-rr-angle-right" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row wrap-logo-img">
              <div className="logo-img col">
                <img
                  src={require('../img/clients/e4effort_logo.png').default}
                  alt="e4effort_logo - Client logo"
                />
              </div>
              <div className="logo-img col">
                <img
                  src={require('../img/clients/plates_logo_header.png').default}
                  alt="plates_logo_header - Client logo"
                />
              </div>
              <div className="logo-img col">
                <img
                  src={require('../img/clients/gaivota_logo_2.png').default}
                  alt="gaivota_logo_2 - Client logo"
                />
              </div>
              <div className="logo-img col">
                <img
                  src={
                    require('../img/clients/logo_instructfitness.png').default
                  }
                  alt="logo_instructfitness - Client logo"
                />
              </div>
              <div className="logo-img col">
                <img
                  src={require('../img/clients/koboldo_logo.png').default}
                  alt="koboldo_logo - Client logo"
                />
              </div>
              <div className="logo-img col">
                <img
                  src={require('../img/clients/prexel_logo.png').default}
                  alt="prexel_logo_network - Client logo"
                />
              </div>
              <div className="logo-img col">
                <img
                  src={require('../img/clients/thales.svg').default}
                  alt="thales - Client logo"
                />
              </div>
              <div className="logo-img col">
                <img
                  src={require('../img/clients/hha_logo.png').default}
                  alt="hha_logo - Client logo"
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Index
